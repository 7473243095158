/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATHS } from './constants/Paths';
import './index.scss';

const App: React.FC = () => {
    return (
        <div className="App">
            {Object.values(PATHS)?.map(({ route, component }) => (
                <Switch key={route}>
                    <Route path={route} component={component} exact />
                </Switch>
            ))}
        </div>
    );
};

export default App;
