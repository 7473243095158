import Icon from '@components/Icon';
import React, { useEffect, useState } from 'react';
import Button from '@components/button';
import { IModalCustomProps, IModalSave, scrollUp } from '.';
import './Modal.scss';

export const Modal: React.FC<IModalCustomProps> = props => {
    const {
        show = false,
        showModal = (): void => {},
        children,
        classesWrapper = '',
        classesModal = '',
        closeIcon = true,
        isLoader = false,
        isTableModal = false,
        additional,
        classAdditional,
        id = '',
    } = props;
    const [showLeaveAnimation, setShowLeaveAnimation] = useState(false);

    setTimeout(() => {
        setShowLeaveAnimation(false);
    }, 600);

    useEffect(() => {
        if (classesModal.includes(scrollUp)) {
            const elements = document.getElementsByClassName(classesModal);
            for (let index = 0, maxLength = elements.length; index < maxLength; index++) {
                elements[index].scrollTop = 0;
            }
        }
    }, [show]);

    return (
        <div
            className={`${classAdditional} ${
                isLoader ? 'z-50' : 'z-40'
            } fixed inset-0 flex items-center justify-center overflow-y-scroll xs:overflow-x-hidden scroll-modal ${
                show ? 'block' : ''
            } ${!show && !showLeaveAnimation ? 'hidden' : ''} ${showLeaveAnimation ? 'animate-leave-modal' : ''} background `}
        >
            <div
                className={`${classesWrapper} relative flex justify-center m-auto min-w-min-mi ${
                    isTableModal ? 'max-w-max-mi' : 'max-w-max-mi'
                }   ${show && 'animate-show-modal'}`}
            >
                <div
                    className={`${classesModal} flex flex-col z-30 ${
                        isTableModal ? 'bg-white max-w-max-mi' : 'bg-gray-light xs:h-screen max-w-max-mi'
                    } min-w-min-mi shadow-modal rounded-2xl p-11 xs:overflow-y-scroll `}
                    id={id}
                >
                    {closeIcon && (
                        <>
                            <div className="xs:hidden">
                                <Icon
                                    name="closeIcon"
                                    onCLick={(): void => {
                                        showModal();
                                    }}
                                    className="absolute cursor-pointer w-8.5 h-8.5 -right-8 -top-10"
                                    alt="close-modal"
                                />
                            </div>
                            <div className="flex justify-end mb-6 mr-6 md:hidden lg:hidden">
                                <Icon
                                    name="closeIconBlue"
                                    onCLick={(): void => {
                                        showModal();
                                    }}
                                    className=" cursor-pointer w-8.5 h-8.5"
                                    alt="close-modal"
                                />
                            </div>
                        </>
                    )}
                    {children}
                    <div className="absolute xs:relative flex justify-center invisible w-0 h-0 xs:w-full xs:h-7.5 xs:visible xs:my-5">
                        {!closeIcon && (
                            <Button
                                text="Atrás"
                                classes="bg-green xs:w-37.5 xs:text-xs"
                                onClick={(): void => {
                                    setShowLeaveAnimation(true);
                                    showModal();
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {additional}
            <div
                className="absolute w-full h-full overflow-y-hidden"
                onClick={(): void => {
                    setShowLeaveAnimation(true);
                    showModal();
                }}
            />
        </div>
    );
};

export const ModalSave: React.FC<IModalSave> = ({ showModal, setShowModal }) => {
    return (
        <Modal
            show={showModal}
            showModal={(): void => {
                setShowModal();
            }}
            closeIcon={false}
            classesWrapper="w-108.25 h-68.75 pt-11.5 pl-11.5 pb-10.75 pr-12.5"
            classesModal="h-68.75 flex justify-center items-center"
        >
            <div className="flex flex-col justify-between text-gray-dark">
                <div className="flex ">
                    <Icon name="checkBlue" className="mr-2.5" />
                    <h3 className="font-bold text-xxl text-blue">Enviado</h3>
                </div>
                <p className="">¡Su formulario ha sido enviado con éxito!</p>
                <br />
                <p className="">Un asesor se estará comunicando con usted en las próximas 48 horas hábiles.</p>
            </div>
            <Button
                text="Aceptar"
                classes="xs:w-37.5 xs:text-xs text-xtinyx w-37.5 mr-4.75  text-white h-8 border bg-blue mt-5 text-center"
                onClick={(): void => {
                    setShowModal();
                }}
            />
        </Modal>
    );
};
