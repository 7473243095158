/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect } from 'react';
import { getIconName } from '@utils/Text';
import { IIconProps, IOutsideloadIconsProps } from '.';

const Icon: React.FC<IIconProps> = ({ className = '', name, alt = '', format = 'svg', onCLick = (): void => {} }) => {
    const [iconName, setIconName] = useState<string>(getIconName(name));

    useEffect(() => {
        setIconName(getIconName(name));
    }, [name]);

    return (
        <img
            src={require(`../../assets/images/${iconName}.${format}`)}
            alt={alt ? alt : name}
            className={className}
            onClick={onCLick}
        />
    );
};
export default Icon;

export const LinkIcons: React.FC<IOutsideloadIconsProps> = ({ className = '', href, nameIcon, classIcon, target = '_self' }) => {
    return (
        <div className={className}>
            <a href={href} target={target}>
                <Icon name={nameIcon} className={`cursor-pointer ${classIcon}`} />
            </a>
        </div>
    );
};
