import React from "react";

export const HEADER_NAV = {
  DESCRIPTION: (
    <>
      <p className="xs:mb-2 xs:font-medium xs:text-center xs:text-white xs:text-2xs xs:font-poppins xs:mt-7 xs:mx-2">
        Centro de Consultoría para la Competitvidad CCxC Colombia SAS
        <span> &nbsp; • </span>&nbsp; NIT 901.084.754-3 &nbsp;
        <span> • </span> &nbsp; Carrera 16 # 93-78 Of. 807
      </p>
    </>
  ),
};
