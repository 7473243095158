import React from 'react';
import Icon from '@components/Icon';
import { IButtonLandingProps } from '.';

import './ButtonLanding.scss';

export const ButtonLanding: React.FC<IButtonLandingProps> = ({ className = '', onClick = (): void => {} }) => {
    return (
        <div className={`button-landing--container ${className}`} onClick={onClick}>
            <div className="button-landing--information">
                ¡Programa tu cita <span>gratis!</span>
                <div className="flex justify-end">
                    <Icon name="mouseClick" className="absolute -mt-5 mr-7" />
                </div>
            </div>
        </div>
    );
};

export const ButtonLandingAboutUs: React.FC<IButtonLandingProps> = ({ className = '', onClick = (): void => {} }) => {
    return (
        <div className={`button-landing-access__container ${className}`} onClick={onClick}>
            <a href="https://app.diggipymes.co/" target="__blank">
                <div className="button-landing-access__information">
                    ¡Ingresa &nbsp; <span> ya!</span>
                </div>
            </a>
        </div>
    );
};

