import React from 'react';
import Icon from '@components/Icon';
import HeaderNav from './components';
import './Header.scss';

const Header: React.FC = () => {
    return (
        <>
            <div className="relative flex items-center justify-center max-w-full">
                <Icon name="headerLanding" className="relative min-h-80.75 w-full" format="png" />
                <Icon name="logoCcxc" className="absolute mt-17 lg:w-108.25 lg:h-35.25 xs:w-62.5 xs:h-20.5" />
                <HeaderNav />
            </div>
        </>
    );
};

export default Header;
