/* eslint-disable @typescript-eslint/no-explicit-any */
export enum HttpMethod {
    POST = 'POST',
    GET = 'GET',
    DELETE = 'DELETE',
    PUT = 'PUT',
}
export enum ServiceType {
    NOTIFICATION = 'NOTIFICATION',
}

export class Request {
    resource: string;
    method: HttpMethod;
    service: ServiceType;
    user_id?: string;
    company_id?: string;
    data?: any;

    /**
     * Constructor
     * @param resource: string - Service resource
     * @param method: HttpMethod - Method request
     * @param service: ServiceType - Service request
     * @param user_id: string - Optional user id
     * @param company_id: string - Optional company id
     * @param data: IGenericRecord[] | IGenericRecord - Optional data request
     */
    constructor(resource: string, method: HttpMethod, service: ServiceType, user_id?: string, company_id?: string, data?: any) {
        this.resource = resource;
        this.method = method;
        this.service = service;
        this.user_id = user_id;
        this.company_id = company_id;
        this.data = data;
    }
}
