export * from './SubtitleText';

export enum SubtitleColor {
    WHITE = 'white',
    BLUE = 'blue',
}

/**
 * This interface contains the subtitleText component props
 *
 * @typeParam color: SubtitleColor - Optional subtitle color value
 * @typeParam className: string - Optional styles props
 */
export interface ISubtitleTextProps {
    color?: SubtitleColor;
    className?: string;
}
