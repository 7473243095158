import { v4 as uuid } from 'uuid';
import { IconsNames, IIconProps } from '@components/Icon';

export * from './AboutUs';

export const CTO_COS = 'CTO_COS';
export const QUALITY = 'CALIDAD';
export const COMMERCIAL = 'COMERCIAL';
export const ACCOUNTING = 'CONTABILIDAD';
export const FINANCIAL = 'FINANCIERA';
export const PRODUCT_INNOVATION = 'INNOVACION_PRODUCTO';
export const LEGAL = 'LEGAL';
export const TALENT = 'TALENTO';
export const TECHNOLOGY = 'TECNOLOGIA';

export interface IAboutUsProps {
    sizeScreen: number;
}

export type Member = {
    id: string;
    name: string;
    occupation: string;
    icon: IconsNames;
};

type Members = {
    [key: string]: Member;
};

export type CollaboratorData = {
    tierOne: Member[] | [];
    tierTwo: Member[] | [];
    tierThree: Member[] | [];
};

type CCXC_COLLAB_DATA = {
    [key: string]: CollaboratorData;
};

export type ButtonData = {
    text: string;
    code: string;
    id: number;
    classes: string;
};

export const iconsAboutUs: IIconProps[] = [
    {
        name: 'madeColombia',
    },
    {
        name: 'icontec',
    },
    {
        name: 'dianSupplier',
    },
    {
        name: 'awsSupplier',
    },
];

const members: Members = {
    HAROLD_VILLALOBOS: {
        id: uuid(),
        name: 'Harold Villalobos',
        occupation: 'Chief Technology Officer',
        icon: 'haroldVillalobos',
    },
    NATALIA_CASTRO: { id: uuid(), name: 'Natalia Castro', occupation: 'Chief of Staff', icon: 'nataliaCastro' },
    JHONIER_GAVIRIA: { id: uuid(), name: 'Jhonier Gaviria', occupation: 'Director I de Calidad', icon: 'jhonierGaviria' },
    EDWIN_CUADRO: { id: uuid(), name: 'Edwin Cuadro', occupation: 'Analista Junior I QA', icon: 'edwinCuadro' },
    DANIELA_LOMBO: { id: uuid(), name: 'Daniela Lombo', occupation: 'Analista Junior I QA', icon: 'danielaLombo' },
    GILBER_CUADRADO: { id: uuid(), name: 'Gilber Cuadrado', occupation: 'Analista Junior I QA', icon: 'gilberCuadrado' },
    FRANK_ATENCIO: { id: uuid(), name: 'Frank Atencio', occupation: 'Analista Junior I QA', icon: 'frankAtencio' },
    YEISON_RICO: { id: uuid(), name: 'Yeison Rico', occupation: 'Analista Junior I QA', icon: 'yeisonRico' },
    VALENTINA_IBANEZ: {
        id: uuid(),
        name: 'Valentina Ibáñez',
        occupation: 'Analista Junior I Control Interno',
        icon: 'valentinaIbanez',
    },
    TATIANA_MOLINA: {
        id: uuid(),
        name: 'Tatiana Molina',
        occupation: 'Directora I Comercial y Talento',
        icon: 'tatianaMolina',
    },
    JUAN_VALDERRAMA: {
        id: uuid(),
        name: 'Juan C. Valderrama',
        occupation: 'Asesor comercial Junior I',
        icon: 'juanValderrama',
    },
    DIANA_PINTO: { id: uuid(), name: 'Diana Pinto', occupation: 'Asesor comercial Junior I', icon: 'dianaPinto' },
    MILENA_MORENO: { id: uuid(), name: 'Milena Moreno', occupation: 'Analista junior II Contable', icon: 'milenaMoreno' },
    SARA_BEJARANO: { id: uuid(), name: 'Sara Bejarano', occupation: 'Contador II', icon: 'saraBejarano' },
    LINA_CARRILLO: { id: uuid(), name: 'Lina Carrillo', occupation: 'Analista junior I Contable', icon: 'linaCarrillo' },
    LISETH_COMBARIZA: { id: uuid(), name: 'Liseth Combaríza', occupation: 'Auxiliar contable', icon: 'lisethCombariza' },
    DIANA_PARDO: { id: uuid(), name: 'Diana Pardo', occupation: 'Lider I Financiera', icon: 'dianaPardo' },
    CAMILO_RODRIGUEZ: {
        id: uuid(),
        name: 'Camilo Rodriguez',
        occupation: 'Analista Junior I Financiero',
        icon: 'camiloRodriguez',
    },
    VIVIANA_ARCILA: { id: uuid(), name: 'Viviana Arcila', occupation: 'Director I de Innovación', icon: 'vivianaArcila' },
    MIGUEL_AREVALO: { id: uuid(), name: 'Miguel Arévalo', occupation: 'Analista Senior UI', icon: 'miguelArevalo' },
    LUCAS_NARVAEZ: { id: uuid(), name: 'Lucas Narváez', occupation: 'Analista Senior UX', icon: 'lucasNarvaez' },
    CAMILA_SANCHEZ: { id: uuid(), name: 'Camila Sánchez', occupation: 'Analista junior II UX/UI', icon: 'camilaSanchez' },
    NICOLAS_SILVA: { id: uuid(), name: 'Nicolas Silva', occupation: 'Analista junior I UX', icon: 'nicolasSilva' },
    ANA_CONTRERAS: {
        id: uuid(),
        name: 'Ana M. Contreras',
        occupation: 'Analista junior I de Innovación',
        icon: 'anaContreras',
    },
    PAOLA_VILLATE: { id: uuid(), name: 'Paola Villate', occupation: 'Lider I Legal', icon: 'paolaVillate' },
    VALENTINA_GAITAN: {
        id: uuid(),
        name: 'Valentina Gaitán',
        occupation: 'Analista de RR.HH I junior',
        icon: 'valentinaGaitan',
    },
    MICHAEL_GALLEGO: {
        id: uuid(),
        name: 'Michael Gallego',
        occupation: 'Director III de Tecnología',
        icon: 'michaelGallego',
    },
    ADAN_QUINTERO: { id: uuid(), name: 'Adán Quintero', occupation: 'Director II Tecnología', icon: 'adanQuintero' },
    NELSON_LIBERATO: { id: uuid(), name: 'Nelson Liberato', occupation: 'Gerente de Proyecto I', icon: 'nelsonLiberato' },
    RONALD_NUNEZ: { id: uuid(), name: 'Ronald Nuñez', occupation: 'Desarrollador Senior', icon: 'ronaldNunez' },
    CRISTIAN_NARVAEZ: { id: uuid(), name: 'Cristian Narváez', occupation: 'Desarrollador Senior', icon: 'cristianNarvaez' },
    JOAN_MARIN: { id: uuid(), name: 'Joan Marín', occupation: 'Desarrollador Senior', icon: 'joanMarin' },
    MARIA_BARRERA: { id: uuid(), name: 'Maria F. Barrero', occupation: 'Desarrollador senior', icon: 'mariaBarrero' },
    YEISON_GONZALES: { id: uuid(), name: 'Yeison González', occupation: 'Desarrollador Estándar', icon: 'yeisonGonzales' },
    ANDRES_SANCHEZ: { id: uuid(), name: 'Andrés Sánchez', occupation: 'Desarrollador Estándar', icon: 'andresSanchez' },
    LUIS_REYES: { id: uuid(), name: 'Luis Reyes', occupation: 'Desarrollador Estándar', icon: 'luisReyes' },
    NICOLAS_SANCHEZ: { id: uuid(), name: 'Nicolas Sánchez', occupation: 'Desarrollador Estándar', icon: 'nicolasSanchez' },
    DAVID_VANEGAS: { id: uuid(), name: 'David Vanegas', occupation: 'Desarrollador Estándar', icon: 'davidVanegas' },
    JOSE_SOLER: { id: uuid(), name: 'Jose Soler', occupation: 'Desarrollador Junior', icon: 'joseSoler' },
    BRIAN_ESPITIA: { id: uuid(), name: 'Brian Espitia', occupation: 'Desarrollador Estándar', icon: 'brianEspitia' },
    NINI_GARZON: { id: uuid(), name: 'Nini Garzón', occupation: 'Desarrollador Estándar', icon: 'niniGarzon' },
    ALEJANDRO_MOTTA: { id: uuid(), name: 'Alejandro Motta', occupation: 'Desarrollador Junior', icon: 'alejandroMotta' },
    DANIEL_BELLO: { id: uuid(), name: 'Daniel Bello', occupation: 'Desarrollador Junior', icon: 'danielBello' },
    LUIS_GONZALEZ: { id: uuid(), name: 'Luis González', occupation: 'Desarrollador Junior', icon: 'luisGonzales' },
    FRANCISCO_GONZALEZ: {
        id: uuid(),
        name: 'Francisco González',
        occupation: 'Desarrollador Estándar',
        icon: 'franciscoGonzales',
    },
    MICHAEL_MURIEL: { id: uuid(), name: 'Michael Muriel', occupation: 'Desarrollador Junior', icon: 'michaelMuriel' },
    JOHN_LONDONO: { id: uuid(), name: 'John Londoño', occupation: 'Desarrollador Junior', icon: 'johnLondono' },
    JHONN_CALDERON: { id: uuid(), name: 'Jhonn Calderón', occupation: 'Desarrollador Junior', icon: 'johnCalderon' },
    MARLON_VARON: { id: uuid(), name: 'Marlon Varón', occupation: 'Desarrollador Junior', icon: 'marlonVaron' },
    DARWIN_SAENZ: { id: uuid(), name: 'Darwin Sáenz', occupation: 'Desarrollador Junior', icon: 'darwinSaenz' },
    MIGUEL_DIAZ: { id: uuid(), name: 'Miguel A. Díaz', occupation: 'Desarrollador Junior', icon: 'miguelDiaz' },
    JUAN_MURCIA: { id: uuid(), name: 'Juan S. Murcia', occupation: 'Desarrollador Junior', icon: 'juanMurcia' },
    JULIANA_FLOREZ: { id: uuid(), name: 'Juliana Florez', occupation: 'Auxiliar de Innovación', icon: 'julianaFlorez' },
    ALEJANDRO_MORENO: {
        id: uuid(),
        name: 'Alejandro Moreno C.',
        occupation: 'Analista junior I Diseño',
        icon: 'alejandroMoreno',
    },
    MANUELA_AGUIRRE: {
        id: uuid(),
        name: 'Manuela Aguirre',
        occupation: 'Analista Junior ll de Mercadeo',
        icon: 'manuelaAguirre',
    },
};

export const CCXC_COLLAB_DATA: CCXC_COLLAB_DATA = {
    [CTO_COS]: { tierOne: [members.HAROLD_VILLALOBOS, members.NATALIA_CASTRO], tierTwo: [], tierThree: [] },
    [QUALITY]: {
        tierOne: [members.HAROLD_VILLALOBOS, members.JHONIER_GAVIRIA],
        tierTwo: [],
        tierThree: [
            members.EDWIN_CUADRO,
            members.DANIELA_LOMBO,
            members.GILBER_CUADRADO,
            members.FRANK_ATENCIO,
            members.YEISON_RICO,
            members.VALENTINA_IBANEZ,
        ],
    },
    [COMMERCIAL]: {
        tierOne: [members.NATALIA_CASTRO, members.TATIANA_MOLINA],
        tierTwo: [],
        tierThree: [members.JUAN_VALDERRAMA, members.DIANA_PINTO, members.MANUELA_AGUIRRE],
    },
    [ACCOUNTING]: {
        tierOne: [members.NATALIA_CASTRO, members.SARA_BEJARANO],
        tierTwo: [],
        tierThree: [members.MILENA_MORENO, members.LINA_CARRILLO, members.LISETH_COMBARIZA],
    },
    [FINANCIAL]: { tierOne: [members.NATALIA_CASTRO, members.DIANA_PARDO], tierTwo: [], tierThree: [members.CAMILO_RODRIGUEZ] },
    [PRODUCT_INNOVATION]: {
        tierOne: [members.HAROLD_VILLALOBOS, members.VIVIANA_ARCILA],
        tierTwo: [members.MIGUEL_AREVALO, members.LUCAS_NARVAEZ],
        tierThree: [
            members.CAMILA_SANCHEZ,
            members.NICOLAS_SILVA,
            members.ANA_CONTRERAS,
            members.ALEJANDRO_MORENO,
            members.JULIANA_FLOREZ,
        ],
    },
    [LEGAL]: { tierOne: [members.NATALIA_CASTRO, members.PAOLA_VILLATE], tierTwo: [], tierThree: [] },
    [TALENT]: { tierOne: [members.NATALIA_CASTRO, members.TATIANA_MOLINA], tierTwo: [], tierThree: [members.VALENTINA_GAITAN] },
    [TECHNOLOGY]: {
        tierOne: [members.HAROLD_VILLALOBOS, members.MICHAEL_GALLEGO, members.ADAN_QUINTERO, members.NELSON_LIBERATO],
        tierTwo: [
            members.RONALD_NUNEZ,
            members.CRISTIAN_NARVAEZ,
            members.JOAN_MARIN,
            members.MARIA_BARRERA,
            members.YEISON_GONZALES,
            members.ANDRES_SANCHEZ,
            members.LUIS_REYES,
            members.NICOLAS_SANCHEZ,
            members.DAVID_VANEGAS,
            members.BRIAN_ESPITIA,
            members.NINI_GARZON,
            members.FRANCISCO_GONZALEZ,
        ],
        tierThree: [
            members.JOSE_SOLER,
            members.ALEJANDRO_MOTTA,
            members.DANIEL_BELLO,
            members.LUIS_GONZALEZ,
            members.MICHAEL_MURIEL,
            members.JOHN_LONDONO,
            members.JHONN_CALDERON,
            members.MARLON_VARON,
            members.DARWIN_SAENZ,
            members.MIGUEL_DIAZ,
            members.JUAN_MURCIA,
        ],
    },
};

export const toCalculateTheLimitArrow = (dataLength: number, counter: number, responsiveLength: number, side: string): boolean =>
    side === 'left' ? counter === 1 : counter + responsiveLength === dataLength;
