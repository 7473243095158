import axios from 'axios';
import { HttpMethod } from '../models/Request';

const baseUrl = process.env.REACT_APP_BASE_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const aboutUs = async (data: any): Promise<any> => {
    try {
        const response = await axios({
            url: `${baseUrl}/notifications/contact-lading`,
            headers: { 'Content-Type': 'application/json' },
            method: HttpMethod.POST,
            data: data,
        });
        return response.status;
    } catch (error) {
        return error;
    }
};
