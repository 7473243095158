import React from 'react';

/**
 * validate alphabetic structure
 *
 * @param e: React.KeyboardEvent<HTMLInputElement> - event
 * @returns void
 */
export const validateAlphabetical = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/g.test(e.key)) e.preventDefault();
};

/**
 * validate alphabetic structure
 *
 * @param e: React.KeyboardEvent<HTMLInputElement> - event
 * @returns void
 */
export const validateNumeric = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (!/^[0-9]$/.test(e.key) && !allowedKeys.includes(e.key)) {
        e.preventDefault();
    }
};

/**
 * validate email structure
 * 
 * @param email: string
 * @returns boolean
 */
export const validateEmail = (email: string): boolean => {
    const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return pattern.test(String(email).toLowerCase());
};
