import React from 'react';
import { LinkIcons } from '@components/Icon/Icon';
import { ROUTES } from '@components/footer';
import Link from '@components/link';
import { PATHS, Routes } from '../../../../constants/Paths';
import { ROUTES_DIGGI } from '.';
import './Footer.scss';

export const Footer: React.FC = () => {
    return (
        <div className="landing-footer--container">
            <div className="relative">
                <div className="landing-footer--body">
                    <div className="flex gap-x-16">
                        <LinkIcons
                            nameIcon="instagramWhite"
                            href={ROUTES_DIGGI.INSTAGRAM}
                            classIcon="landing-footer--icon"
                            target="_blank"
                        />
                        <LinkIcons
                            nameIcon="linkedinWhite"
                            href={ROUTES_DIGGI.LINKEDIN}
                            classIcon="landing-footer--icon"
                            target="_blank"
                        />
                        <LinkIcons
                            nameIcon="youtubeWhite"
                            href={ROUTES_DIGGI.YOUTUBE}
                            classIcon="landing-footer--icon"
                            target="_blank"
                        />
                    </div>
                    <div className="landing-footer--information">
                        <p className="text-center">
                            Centro de Consultoría para la Competitividad CCxC Colombia SAS &nbsp; • &nbsp; NIT 901.084.754-3
                            &nbsp; • &nbsp; Carrera 16 # 93-78 Of. 807
                        </p>
                        <div className="flex justify-center mt-1 xs:mt-2">
                            <Link href={ROUTES.TERMS} text="Términos y condiciones" target="_blank" outside />
                            <p className="mx-1">&nbsp; • &nbsp;</p>
                            <Link href={ROUTES.DATA_TREATMENT} text="Política" target="_blank" outside />
                            <p className="mx-1">&nbsp; • &nbsp;</p>
                            <Link href={PATHS[Routes.HOME].route} text=" Sugerencias y reclamos" target="_blank" />
                        </div>
                    </div>
                </div>
                <img src={require('@assets/images/footer-background.png')} alt="footer" className="landing-footer--background" />
            </div>
        </div>
    );
};
