import React from 'react';
import { Link as LinkComponent } from 'react-router-dom';
import { IButtonProps } from '.';
import './Button.scss';

const Button: React.FC<IButtonProps> = ({
    classes,
    onClick,
    text,
    link = '',
    classContainer = '',
    target = '_self',
    outside,
}) => {
    return (
        <>
            {outside ? (
                <a href={link} download className={`btn ${classContainer}`} target={target}>
                    {text}
                </a>
            ) : (
                <LinkComponent to={link} className={classContainer} target={target}>
                    <div className={` btn ${classes} `} onClick={onClick}>
                        <p>{text}</p>
                    </div>
                </LinkComponent>
            )}
        </>
    );
};

export default Button;
