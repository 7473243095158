export * from './Footer';


/**
 * This constants routes
 */
 export const ROUTES_DIGGI = {
    INSTAGRAM: 'https://www.instagram.com/diggipymes/',
    LINKEDIN: 'https://www.linkedin.com/company/ccxc',
    YOUTUBE: 'https://www.youtube.com/channel/UClPmwyTAXJdvtTwYWcSWceg',
};