import React from 'react';
export * from './Modal';

/**
 * This interface describes what properties the custom modal component receives
 *
 * @typeParam show: boolean - Show State boolean to show modal
 * @typeParam showModal: () => void - ShowModal Function to change state of modal
 * @typeParam children: React.ReactNode - Children Elements of which the modal receives as a container
 * @typeParam classesWrapper: string - Optional class wrapper to add styles modal
 * @typeParam classesModal: string - Optional class modal to add styles content modal
 * @typeParam closeIcon: boolean - Optional icon for close modal is showing or not
 * @typeParam isLoader: boolean - Optional modal is used for showing loading page
 * @typeParam additional:  React.ReactNode - Optional modal Children Elements of which the modal additional
 * @typeParam classAdditional: string - Optional class additional to add styles modal
 * @typeParam id: string - Optional prop for defining element's id
 */
export interface IModalCustomProps {
    show: boolean;
    showModal: () => void;
    children: React.ReactNode;
    classesWrapper?: string;
    classesModal?: string;
    closeIcon?: boolean;
    isLoader?: boolean;
    isTableModal?: boolean;
    additional?: React.ReactNode;
    classAdditional?: string;
    id?: string;
}

/**
 * This interface describes that properties the modal component receives
 * @typeParam showModal: boolean - Require state modal
 * @typeParam setShowModal: () => void - Require function state modal
 */
export interface IModalSave {
    showModal: boolean;
    setShowModal: () => void;
}

export const scrollUp = 'info-scroll-up';
