import Home from '@pages/home';
import { IPath } from '@models/Paths';
import { LandingDiggiPymes } from '@pages/landing-diggipymes';

export enum Routes {
    HOME,
    LANDING_DIGGIPYMES,
}

export const PATHS: IPath = {
    [Routes.HOME]: {
        route: '/',
        component: Home,
        title: 'Home',
    },
    [Routes.LANDING_DIGGIPYMES]: {
        route: '/landing-diggipymes',
        component: LandingDiggiPymes,
        title: 'landing-diggipymes',
    },
};
