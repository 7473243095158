export * from './LandingDiggiPymes';

/**
 * This initial data
 */
export const INITIAL_DATA = {
    name_surname: '',
    email: '',
    phone: '',
    hour: '',
    date: '',
    type: 'DIGGIPYMES',
};
