import React from 'react';
import { ISubtitleTextProps, SubtitleColor } from '.';
import './SubtitleText.scss';

export const SubtitleText: React.FC<ISubtitleTextProps> = ({ color = SubtitleColor.WHITE, className = '' }) => {
    return (
        <div className={`subtitle-text text-${color} ${className}`}>
            <p className={``}>
                Te capacitamos para gestionar de manera eficiente y eficaz tu empresa a través del uso de tecnología.
            </p>
        </div>
    );
};
