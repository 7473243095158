export const lowerCase = (word = ""): string => word?.toLowerCase();

export const upperCase = (word: string): string => word?.toUpperCase();

export const getIconName = (name: string): string => {
  let result = "";
  for (let i = 0; i < name.length; i++) {
    const letter = name[i];
    result += letter === upperCase(letter) ? `-${lowerCase(letter)}` : letter;
  }
  return result;
};
