export { default } from './FooterLanding';

/**
 * These interfaces are used to footer landing component
 *
 * @typeParam sizeScreen: number - Require size screen 

 */
export interface IFooterLanding {
    sizeScreen: number;
}

/**
 * This constants routes
 */
export const ROUTES = {
    TERMS: 'https://www.ccxc.co/terminos-y-condiciones.html',
    DATA_TREATMENT: 'https://www.ccxc.co/politica-tratamiento-de-datos.html',
    DATA_TREATMENT_PRIVATE_THIRD_PARTIES:
        'https://storageccxc1.s3.us-west-2.amazonaws.com/famiefi/3006b3a2-332c-4244-970a-67c72d9901b8/security/politic/2024-07-24-bdab638c-be41-456e-b5c4-c30216450206-1721855113.pdf',
    DATA_TREATMENT_SECURITY_INFORMATION: 'assets/docs/POLITCA_GENERAL_SEGURIDAD_PRIVACIDAD_INFORMACION.pdf',
    INSTAGRAM: 'https://www.instagram.com/ccxcfintech/',
    LINKEDIN: 'https://www.linkedin.com/company/ccxc',
    FACEBOOK: 'https://www.facebook.com/people/CCxC-Centro-de-Consultor%C3%ADa-para-la-Competitividad/100063803462375/',
    TWITTER: 'https://twitter.com/CCxCFinTech',
    YOUTUBE: 'https://www.youtube.com/channel/UCLc2YCPb6mC-Jd7d0vyHg9w',
};
