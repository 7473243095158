import React from 'react';
import Icon from '@components/Icon';
import { iconsAboutUs } from '.';

export const AboutUs: React.FC = () => {
    return (
        <div className="flex xs:flex-wrap justify-center xs:gap-10 lg:gap-x-20 mt-2.5">
            {iconsAboutUs.map((icon, index) => (
                <Icon {...icon} className="max-w-70 lg:w-full lg:h-full max-h-17.5 xs:mt-4.75" key={`${icon.name}-${index}`} />
            ))}
        </div>
    );
};
