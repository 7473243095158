import React from 'react';
import { Link as LinkComponent } from 'react-router-dom';
import { ILinkProps } from '.';

const Link: React.FC<ILinkProps> = ({
    text,
    href,
    classes = '',
    target = '_self',
    onClick = (): void => {},
    outside = false,
}: ILinkProps) => {
    const linkText = text?.includes('\n') ? text.split('\n') : [text];
    const getPadding = (index: number): string => (index ? 'pl-0' : '');

    return (
        <>
            {outside ? (
                <a href={href} download className={classes} target={target}>
                    {text}
                </a>
            ) : (
                <LinkComponent to={href} className={`font-poppins ${classes}`} target={target} onClick={onClick}>
                    {linkText.map((line: string, index: number) => (
                        <span key={line} className={getPadding(index)}>
                            {line}
                        </span>
                    ))}
                </LinkComponent>
            )}
        </>
    );
};
export default Link;
