import axios from 'axios';
import { HttpMethod } from '../models/Request';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getPrefixNumber = async (): Promise<any> => {
    try {
        const response = await axios({
            url: `${baseUrl}/notifications/prefix-number`,
            headers: { 'Content-Type': 'application/json' },
            method: HttpMethod.GET,
            data: [],
        });
        return response.data;
    } catch (error) {
        return error;
    }
}