import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { IFormData } from './models/Form';
import { Modal, ModalSave } from '@components/modal';
import { BorderColors, LabelColors, TextInput } from '@components/input';
import { CheckboxMain } from '@components/checkbox';
import Icon from '@components/Icon';
import Link from '@components/link';
import { aboutUs } from '../../services/aboutUs';
import { SUCCESS_RESPONSE } from '../../constants/StatusCode';
import { Footer } from './components/footer';
import { SubtitleColor, SubtitleText } from './components/subtitle-text';
import { ButtonLanding, ButtonLandingAboutUs } from './components/button-landing';
import { INITIAL_DATA } from '.';
import './LandingDiggiPymes.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import es from 'date-fns/locale/es';
import { ROUTES } from '@components/footer';

registerLocale('es', es);

export const LandingDiggiPymes: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [check, setCheck] = useState<boolean>(false);
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [dataForm, setDataForm] = useState(INITIAL_DATA);
    const [showModalSave, setShowModalSave] = useState(false);

    const [form, setForm] = useState<IFormData>({
        name_surname: {
            value: '',
            placeHolder: 'Nombre y apellido',
        },
        email: {
            value: '',
            placeHolder: 'Correo electrónico',
        },
        phone: {
            value: '',
            placeHolder: 'Teléfono',
        },
        date: {
            value: '',
            placeHolder: 'Fecha:',
        },
        hour: {
            value: '',
            placeHolder: 'Hora',
        },
    });

    const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>, item: IFormData): void => {
        const { value, name } = e.target;
        setDataForm({ ...dataForm, [name]: value });
        if (name) {
            setForm({
                ...form,
                [name]: {
                    ...item,
                    value,
                },
            });
        }
    };

    useEffect(() => {
        setDataForm({ ...dataForm, hour: moment(new Date()).format('HH:mm'), date: new Date().toLocaleDateString() });
    }, []);

    const saveForm = async (): Promise<void> => {
        const status = await aboutUs(dataForm);
        if (SUCCESS_RESPONSE.includes(Number(status))) {
            setDataForm(INITIAL_DATA);
            setForm({
                name_surname: { ...form.name_surname, value: '' },
                email: { ...form.email, value: '' },
                phone: { ...form.phone, value: '' },
                date: { ...form.date, value: '' },
                hour: { ...form.hour, value: '' },
            });
            setCheck(false);
            setShow(false);
            setShowModalSave(true);
        }
    };

    return (
        <div className="landing-header--container">
            <ModalSave
                showModal={showModalSave}
                setShowModal={(): void => {
                    setShowModalSave(!showModalSave);
                }}
            />
            <Modal classesModal="w-90 items-center" classesWrapper="w-90" show={show} showModal={(): void => setShow(!show)}>
                <div className="landing-modal">
                    <div className="landing-modal--information">
                        <h1>
                            ¡Regístrate y lleva tu negocio a <span>otro nivel!</span>
                        </h1>
                    </div>
                    <div className="flex flex-col items-center gap-y-4">
                        {Object.entries(form)
                            .slice(0, 3)
                            .map(([key, item]) => (
                                <TextInput
                                    key={key}
                                    value={item.value}
                                    name={key}
                                    label={item.placeHolder}
                                    labelColor={LabelColors.GRAY}
                                    borderColor={BorderColors.GREEN}
                                    placeholder="Ej: xxxxxx"
                                    onChange={(e): void => handleChangeForm(e, item)}
                                    classesInput="placeholder:bg-white"
                                    containerClass="bg-white"
                                />
                            ))}
                    </div>
                    <div className="flex justify-center mt-4 gap-x-5">
                        <div className="flex flex-col justify-end">
                            <label className="text-xs text-gray-dark">Fecha:</label>
                            <div className="relative flex items-center h-8 px-2 bg-white border rounded-lg border-green w-34 ">
                                <DatePicker
                                    name="date"
                                    selected={startDate}
                                    onChange={(date: Date): void => {
                                        setStartDate(date);
                                        setDataForm({ ...dataForm, date: date.toLocaleDateString() });
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className="w-full text-xs bg-white border border-none text-gray outline-0"
                                />
                                <Icon name="calendarGreen" className="absolute bottom-2 right-2" />
                            </div>
                        </div>
                        <div className="flex flex-col justify-end">
                            <label className="ml-1 text-xs text-gray-dark">Hora:</label>
                            <div className="relative flex items-center h-8 px-2 bg-white border rounded-lg border-green w-34 ">
                                <DatePicker
                                    selected={startTime}
                                    onChange={(date: Date): void => {
                                        setStartTime(date);
                                        setDataForm({ ...dataForm, hour: moment(date).format('HH:mm') });
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="w-full text-xs bg-white border border-none text-gray outline-0"
                                />
                                <Icon name="watchGreen" className="absolute bottom-2 right-2" />
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <CheckboxMain checked={check} onChange={(): void => setCheck(!check)} />
                        <Link
                            href={ROUTES.DATA_TREATMENT}
                            text="Autorizo política de tratamiento de datos"
                            target="_blank"
                            outside
                            classes="mt-5 text-xs text-purple-dark"
                        />
                    </div>
                    <button
                        className="landing-modal--button"
                        onClick={(): void => {
                            saveForm();
                        }}
                    >
                        Registrarme
                    </button>
                    <div className="flex mt-10">
                        <Icon name="ccxcIconGray" />
                        <div className="mx-4">
                            <Icon name="lineModal" />
                        </div>
                        <Icon name="diggiPymesIconGray" />
                    </div>
                </div>
            </Modal>
            <div className="w-full">
                <img
                    src={require('@assets/images/background-landing.png')}
                    className="relative z-10 landing-header--background"
                    alt="image-background"
                />
                <div className="absolute top-0 z-20 w-full ">
                    <div className="w-full xs:p-0 pl-17 pt-[120px] landing-header--information">
                        <div>
                            <p className="landing-header--title">Integra y automatiza los procesos internos de tu empresa.</p>
                            <SubtitleText className="xs:hidden" />
                            <div className="flex gap-x-4">
                                <ButtonLanding className="xs:hidden" onClick={(): void => setShow(!show)} />
                                <ButtonLandingAboutUs className="xs:hidden" />
                            </div>
                        </div>
                    </div>
                    <div className="top-0 landing-header--logo">
                        <Icon name="diggiPymesWhite" />
                    </div>
                    <div className="pl-[487px] xs:pl-0 landing-header--image">
                        <Icon name="pcDiggi" className="xs:w-90 lg:w-198.25 md:w-108.25" />
                    </div>
                </div>
            </div>

            <div className="landing-body">
                <SubtitleText color={SubtitleColor.BLUE} className="lg:hidden md:hidden xs:mt-10" />
                <div className="flex flex-col items-center">
                    <ButtonLanding className="lg:hidden md:hidden" onClick={(): void => setShow(!show)} />
                    <ButtonLandingAboutUs className="lg:hidden md:hidden" />
                </div>
                <div className="flex flex-col items-center">
                    <div className="landing-body-information--container">
                        <div className="landing-body--information">
                            <Icon name="diggiPymes" className="landing-body-information--logo" />
                            <div className="landing-body--text">
                                <p>
                                    Es su administrador digital encargado de &nbsp;
                                    <span className="landing-body--text--span-green">
                                        integrar y automatizar los procesos internos de su empresa &nbsp;
                                    </span>
                                    con el fin de &nbsp;
                                    <span className="landing-body--text--span-blue">
                                        incrementar sus ventas, disminuir costos, alcanzar más clientes, aprovechar mejor su
                                        tiempo y dedicarse a lo que realmente necesita.
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="landing-body--benefits">
                            <h1>Nuestros beneficios</h1>
                            <div className="landing-body--benefits-container">
                                <Icon name="benefitReports" />
                                <div className="landing-body--benefits-information">
                                    <h2>Reportes analíticos</h2>
                                    <p>
                                        Conoce la <span>evolución y comportamiento</span> de tus productos, servicios, clientes y
                                        proveedores.
                                    </p>
                                </div>
                            </div>
                            <div className="landing-body--benefits-container">
                                <Icon name="benefitBills" />
                                <div className="landing-body--benefits-information">
                                    <h2>¡Factura ilimitadamente!</h2>
                                    <p>
                                        Crea facturas de manera fácil y rápida <span>sin límite de documentos.</span>
                                    </p>
                                </div>
                            </div>
                            <div className="landing-body--benefits-container">
                                <Icon name="benefitSales" />
                                <div className="landing-body--benefits-information">
                                    <h2>¡Incrementa tus ventas! </h2>
                                    <p>
                                        Aumenta las ventas de tu negocio con una <span>tienda virtual.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
